<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/memberActivity' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('memberActivity')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="memberActivity"
                  ref="memberActivity"
                  label-width="150px"
                  class="memberActivity"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="用户名称" prop="memberName">
                        <el-input
                          v-model="memberActivity.memberName"
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="封面图" prop="coverUrl">
                        <el-input v-model="memberActivity.coverUrl"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="标题（活动名）" prop="title">
                        <el-input v-model="memberActivity.title"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="地址" prop="address">
                        <el-input v-model="memberActivity.address"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="开始时间" prop="startDt">
                        <span>{{ memberActivity.startDt | dataFormat }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="结束时间" prop="endDt">
                        <span>{{ memberActivity.startDt | dataFormat }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="活动人数" prop="num">
                        <el-input v-model="memberActivity.num"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="验证码" prop="verCode">
                        <el-input v-model="memberActivity.verCode"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="活动描述" prop="describeInfo">
                        <el-input
                          v-model="memberActivity.describeInfo"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="创建时间" prop="createDt">
                        <el-input v-model="memberActivity.createDt"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="状态" prop="status">
                        <span v-if="memberActivity.status === '0'">未审核</span>
                        <span v-if="memberActivity.status === '1'"
                          >审核通过</span
                        >
                        <span v-if="memberActivity.status === '2'"
                          >审核失败</span
                        >
                        <span v-if="memberActivity.status === '3'"
                          >活动失效</span
                        >
                        <!-- <el-input v-model="memberActivity.status"></el-input> -->
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="失败原因" prop="failReason">
                        <el-input
                          v-model="memberActivity.failReason"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="是否是热门活动" prop="isHot">
                        <!-- <el-input v-model="memberActivity.isHot"></el-input> -->
                        <span v-if="memberActivity.isHot === '0'">不是</span>
            <span v-if="memberActivity.isHot === '1'">是</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="标签" prop="label">
                        <el-input v-model="memberActivity.label"></el-input>
                      </el-form-item>
                    </el-col>
                    
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="省" prop="provinceName">
                        <el-input
                          v-model="memberActivity.provinceName"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="城市" prop="cityName">
                        <el-input v-model="memberActivity.cityName"></el-input>
                      </el-form-item>
                    </el-col>
                    
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="区县" prop="districtName">
                        <el-input
                          v-model="memberActivity.districtName"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    
                    
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="活动进行状态" prop="statusing">
                        <!-- <el-input v-model="memberActivity.statusing"></el-input> -->
                        <span v-if="memberActivity.statusing === '0'">报名中</span>
            <span v-if="memberActivity.statusing === '1'">进行中</span>
            <span v-if="memberActivity.statusing === '2'">已结束</span>
            <span v-if="memberActivity.statusing === '3'">解散</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="活动类型" prop="type">
                        <!-- <el-input v-model="memberActivity.type"></el-input> -->
                        <span v-if="memberActivity.type === '1'">公开活动</span>
            <span v-if="memberActivity.type === '2'">私密活动</span>
            <span v-if="memberActivity.type === '3'">惠购活动</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="memberActivity.phone"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="配送单价" prop="deliveryFee">
                        <el-input
                          v-model="memberActivity.deliveryFee"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item
                        label="配送费上限"
                        prop="limitDeliveryFee"
                      >
                        <el-input
                          v-model="memberActivity.limitDeliveryFee"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="街道" prop="streetName">
                        <el-input
                          v-model="memberActivity.streetName"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="发布活动来源" prop="source">
                        <!-- <el-input v-model="memberActivity.source"></el-input> -->
                        <span v-if="memberActivity.source === '1'">YOpro</span>
            <span v-if="memberActivity.source === '2'">智慧社区</span>
                      </el-form-item>
                    </el-col>
                    
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="供应商名称" prop="supplierName">
                        <el-input
                          v-model="memberActivity.supplierName"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="是否创建群聊" prop="isGroup">
                        <el-input v-model="memberActivity.isGroup"></el-input>

                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="限制几星参加" prop="star">
                        <el-input v-model="memberActivity.star"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--  多功能 -->
            <MemberActivityFunction></MemberActivityFunction>

            <!-- 扩展-->
            <MemberActivityExtends></MemberActivityExtends>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import MemberActivityFunction from "@/views/memberActivity/MemberActivityFunction.vue";
import MemberActivityExtends from "@/views/memberActivity/MemberActivityExtends.vue";
export default {
  components: {
    MemberActivityFunction,
    MemberActivityExtends,
  },
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息", "多功能", "扩展属性"],
      memberActivity: {
        id: "",
        memberCode: "",
        memberName: "",
        activityCode: "",
        coverUrl: "",
        title: "",
        address: "",
        startDt: "",
        endDt: "",
        num: "",
        verCode: "",
        describeInfo: "",
        createDt: "",
        status: "",
        failReason: "",
        isHot: "",
        label: "",
        provinceCode: "",
        provinceName: "",
        cityCode: "",
        cityName: "",
        districtCode: "",
        districtName: "",
        longitude: "",
        latitude: "",
        statusing: "",
        type: "",
        phone: "",
        deliveryFee: "",
        limitDeliveryFee: "",
        streetCode: "",
        streetName: "",
        source: "",
        supplierCode: "",
        supplierName: "",
        isGroup: "",
        star: "",
      },
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/memberActivity",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      that.$http
        .post("/memberActivity/save", that.memberActivity)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/memberActivity/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.memberActivity = response.data.data;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
